import React from "react";
import "../Engineering-Proj/EngineeringProject.css";
import "./DataManagement.css";
import Footer from "../../Footer/Footer";

function DataManagement() {
  return (
    <>
      <div>
        <div className="Datamanagement-first">
          <img
            src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/pexels-brett-sayles-4276427.jpg"
            alt="show"
          />
          <h2>Data Management</h2>
        </div>
        <div className="datamanagement-middle">
          <div className="datamanagement-middle-content">
            <h3>LNG Maintenance Management System Implementation</h3>
          </div>
          <div className="datamanagement-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023…ook-LNG-Terminal-gradient-3200x1404-1-768x337.jpg"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagement-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnico was contracted to develop a maintenance management
                system for two LNG facilities. Through consultation with the
                client, Tecnico developed the overall maintenance strategy and
                implemented this program using IBM’s Maximo software. The Sabine
                Pass Liquefaction facility project started in 2013 and continues
                today.
              </p>
            </div>
          </div>
          <div className="datamanagement-last-right">
            <h4>Services</h4>
            <div className="datamanagement-services-2">
              <ul>
                <li>Maintenance management philosophy development</li>
                <li>Asset register development</li>
                <li>Preventive maintenance (PM) plans and procedures</li>
                <li>Corrective maintenance plans and procedures</li>
                <li>Document linking to asset register and PMs</li>
                <li>
                  Bill of material development – research and identify turbine
                  parts for interchangeability, better descriptions and build
                  BOMs
                </li>
                <li>Coordinate spare part stocking reviews</li>
                <li>Integrate repairable spares and PSVs with supply chain</li>
                <li>Coordinate operational readiness activities</li>
                <li>Routine and turnaround scheduling support</li>
                <li>
                  Develop and strategize regulatory compliance including linking
                  regulations in Maximo for simpler audit
                </li>
                <li>
                  Develop specifications and test PCMS RBI tool integration with
                  Maximo
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="datamanagements-middle">
          <div className="datamanagements-middle-content">
            <h3>
              Gas Processing Facility Maintenance Management System
              Implementation
            </h3>
            <span>Reeves County, TX, USA</span>
          </div>
          <div className="datamanagements-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/Image32370.jpg"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagements-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to assist with the design and
                development of a maintenance management system for a large gas
                processing and compression operation in West Texas. After
                defining the project drivers with the client, M&H developed the
                overall maintenance strategy and implemented a complete
                computerized maintenance management system (CMMS).
              </p>
              <p>
                The project continues today with the expansion of the
                maintenance system to incorporate additional field development
                and additional maintenance system features.
              </p>
            </div>
          </div>
          <div className="datamanagements-last-right">
            <h4>Scope</h4>
            <div className="datamanagement-services">
              <ul>
                <li>Maintenance management philosophy design</li>
                <li>SAP PM asset register development and load support</li>
                <li>SAP PM maintenance plan development and load support</li>
                <li>
                  SAP mobile work manager (Agentry) on iPads support & training
                </li>
                <li>On-site SAP maintenance tech and operator training</li>
                <li>Document linking to asset register and PMs</li>
                <li>Mobile barcode scanning support</li>
                <li>Maintenance, operating, and LOTO procedure development</li>
                <li>
                  MOC (Management of Change) and P&ID (Piping and
                  Instrumentation Diagram) redline drafting support
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="datamanagements-middle">
          <div className="datamanagements-middle-content">
            <h3>
              Maintenance Management System Implementation and Procedure
              Development
            </h3>
            <span>Multiple Processing Facilities, USA</span>
          </div>
          <div className="datamanagements-middle-img">
            <img
              src="	https://www.tecnicoepc.com/wp-content/uploads/2023…-ship-management_1000x500_tcm8-143243-768x384.jpg"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagements-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted by a prevalent midstream company to
                implement Maximo at approximately twenty-five processing plants.
              </p>
              <p>
                Tecnicoe was also contracted to develop maintenance procedures
                and lockout/tagout procedures for the same facilities.
              </p>
            </div>
          </div>
          <div className="datamanagements-last-right">
            <h4>Scope</h4>
            <div className="datamanagement-services">
              <ul>
                <li>Develop location and asset hierarchy based on Prides</li>
                <li>Define critical/non-critical assets</li>
                <li>
                  Develop PMs (establish frequency, responsibility, last
                  performed date, etc.)
                </li>
                <li>Develop job plans and assign to PMs</li>
                <li>Load data</li>
                <li>Develop training material</li>
                <li>Provide onsite training</li>
                <li>Maintenance procedure development</li>
                <li>LOTO (Lockout/Tagout) procedure development</li>
                <li>Post go-live support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DataManagement;
