import React from "react";
import "../Engineering-Proj/EngineeringProject.css";
import Footer from "../../Footer/Footer";

function EngineeringProject() {
  return (
    <>
      <div>
        <div className="Datamanagement-first">
          <img
            src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/pexels-brett-sayles-4276427.jpg"
            alt="show"
          />
          <h2>Engineering Project</h2>
        </div>
        <div className="datamanagement-middle">
          <div className="datamanagement-middle-content">
            <h3>Onshore Oil and Gas Processing Facility</h3>
            <span>Anchor Point, AK, USA</span>
          </div>
          <div className="datamanagement-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/pexels-photomix-company-224924-1200x800.jpg"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagement-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to design and construct a production
                facility on the Kenai Peninsula of Alaska for 20 oil production
                and 10 water injection wells. The project was completed on
                schedule and under budget in 2016.
              </p>
              <p>
                The facility contains oil and gas processing equipment and is
                rated at a capacity of 10,000 BOPD, 10,000 BWPD, and 15 MMSCFD.
              </p>
              <p>
                The facility includes equipment to process, treat, and export
                natural gas to the local pipeline utility by using a series of
                reciprocating gas compressors, glycol dehydration, and onsite
                measurement. Oil is treated through separation, heat and
                filtration, and chemical treating before being metered through
                LACT units and exported by truck. Water is separated, treated,
                and stored in tanks before being exported offsite.
              </p>
            </div>
            <div className="datamanagement-content-two">
              <h3>EQUIPMENT & SYSTEMS INCLUDED</h3>

              <ul>
                <li>3 phase separator</li>
                <li>Direct fired heater treater and coalescer</li>
                <li>Shell and tube heat exchangers</li>
                <li>Multiphase flow meter</li>
                <li>High and low pressure flare scrubbers</li>
                <li>High and low pressure flare stack</li>
                <li>Truck loading vapor combustor</li>
                <li>Glycol dehydration unit</li>
                <li>Chemical injection system</li>
                <li>API 650 storage tanks</li>
                <li>Process control system (Wonderware)</li>
                <li>Produced water skimmer</li>
                <li>LACT units</li>
                <li>Bi-directional prover loop</li>
                <li>Truck loading terminal</li>
                <li>Fuel gas conditioning</li>
                <li>Vapor recovery units</li>
                <li>High and low pressure reciprocating compressors</li>
                <li>Instrument air system</li>
                <li>Closed drain system</li>
                <li>Microturbine power generators</li>
                <li>PDC/MCC building</li>
              </ul>
            </div>
          </div>
          <div className="datamanagement-last-right">
            <h4>Services</h4>
            <div className="datamanagement-services">
              <ul>
                <li>Project management and controls</li>
                <li>Process engineering and design</li>
                <li>Mechanical engineering and piping design</li>
                <li>Civil engineering and foundation design</li>
                <li>
                  Electrical/instrumentation engineering and control system
                  integration
                </li>
                <li>Procurement</li>
                <li>Vendor coordination and expediting</li>
                <li>Fabrication inspection and QC</li>
                <li>Construction management</li>
                <li>Operating procedures development</li>
                <li>Operations training</li>
                <li>Permitting support</li>
                <li>Commissioning and operations support</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="datamanagements-middle">
          <div className="datamanagements-middle-content">
            <h3>Compressor Station</h3>
            <span>Reeves County, TX, USA</span>
          </div>
          <div className="datamanagements-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/Construct-Compressor-Station.jpg"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagements-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to engineer and design a compressor
                station including dehydration equipment. The facility is
                installed on a four-acre plot, with separation, compression, and
                dehydration systems sized for 40 MMSCFD. The facility was
                designed so that it can be easily expanded.
              </p>
            </div>
            <div className="datamanagements-content-two">
              <h3>EQUIPMENT & SYSTEMS INCLUDED</h3>
              <ul>
                <li>Compressors</li>
                <li>Inlet separator</li>
                <li>Filter separators</li>
                <li>Dehydration packages</li>
                <li>Fuel gas skid</li>
                <li>Meter skid</li>
                <li>Storage tanks</li>
                <li>Pipeline pig receiver/launcher</li>
              </ul>
            </div>
          </div>
          <div className="datamanagements-last-right">
            <h4>Scope</h4>
            <div className="datamanagement-services">
              <ul>
                <li>Project management and controls</li>
                <li>Process engineering and design</li>
                <li>Mechanical engineering and piping design</li>
                <li>Civil engineering and foundation design</li>
                <li>Procurement support</li>
                <li>Electrical and instrumentation engineering and design</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="datamanagements-middle">
          <div className="datamanagement-middle-content">
            <h3>Pipeline Meter Stations</h3>
            <span>West Texas, USA</span>
          </div>
          <div className="datamanagement-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/Pipeline-Metering-5-1200x900.webp"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagements-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to provide engineering and design
                support to develop construction drawings for multiple meter
                stations in west Texas and around the country. Meter stations
                often include hot tap design, riser design, ROW and permit
                drawings, and all required drawings for construction. The scope
                can also include survey and geotechnical requirements. The
                design is performed in accordance with local DOT requirements,
                company specifications, and industry standards.
              </p>
            </div>
            <div className="datamanagements-content-two">
              <h3>EQUIPMENT & SYSTEMS INCLUDED</h3>
              <ul>
                <li>Meter run</li>
                <li>RTD building</li>
                <li>Pig launcher and receivers</li>
                <li>Hot tap</li>
                <li>Controls for bi-directional flow</li>
                <li>HDD design</li>
                <li>Pipeline lateral design</li>
              </ul>
            </div>
          </div>
          <div className="datamanagements-last-right">
            <h4>Scope</h4>
            <div className="datamanagement-services">
              <ul>
                <li>Project management and controls</li>
                <li>Process engineering and design</li>
                <li>Mechanical engineering and piping design</li>
                <li>Civil engineering and foundation design</li>
                <li>Electrical and instrumentation engineering and design</li>
                <li>Procurement support</li>
                <li>Geotechnical support</li>
                <li>Survey support</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="datamanagements-middle">
          <div className="datamanagements-middle-content">
            <h3>Pipeline Pump Station</h3>
            <span>Dunn County, ND, USA</span>
          </div>
          <div className="datamanagements-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/maxresdefault-2-1200x675.jpg"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagements-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to engineer and design a produced water
                pump station in Dunn County, North Dakota.
              </p>
              <p>
                The facility was installed on a one-acre plot, with booster
                pumps and water tanks sized for combined storage of 3,000
                barrels. Water from the existing pipeline was routed to storage
                tanks where it is stored prior to pumping into the departing
                pipeline. The pumps discharge at 450 BPH and 400 PSIG. Space has
                been allocated for additional oil tanks and booster pumps for
                future expansion.
              </p>
            </div>
            <div className="datamanagements-content-two">
              <h3>EQUIPMENT & SYSTEMS INCLUDED</h3>
              <ul>
                <li>Water storage tanks (1,000 BBLS)</li>
                <li>Booster pump</li>
                <li>Water pump building</li>
                <li>Facility heat tracing panel</li>
              </ul>
            </div>
          </div>
          <div className="datamanagements-last-right">
            <h4>Scope</h4>
            <div className="datamanagement-services">
              <ul>
                <li>Project management and controls</li>
                <li>Process engineering and design</li>
                <li>Mechanical engineering and piping design</li>
                <li>Civil engineering and foundation design</li>
                <li>Electrical and instrumentation engineering and design</li>
                <li>Construction SOW development </li>
                <li>Geotechnical investigation</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="datamanagements-middle">
          <div className="datamanagement-middle-content">
            <h3>Gas Plant Repurposing</h3>
            <span>Amarillo, TX, USA</span>
          </div>
          <div className="datamanagement-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/download-8-1200x646.jpg"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagements-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to complete a FEED study and the
                subsequent detailed engineering to repurpose a gas plant located
                in Amarillo, TX. The goal of the repurposing project was to
                bypass and abandon the existing cryogenic treatment facilities
                while providing a fit for purpose upgrade to existing process
                equipment and the addition of new equipment in order to meet the
                required specifications for sales of gas and liquids to a new
                buyer.
              </p>
              <p>
                Design flow rates for the repurposing project were 50 MMSCFD
                with up to 300 PPM H2S and 2% CO2 and 2,500 BLPD with up to
                1,200 ppm H2S and .1% CO2. The facility repurposing was designed
                and organized in a way that would allow for the facility to
                remain operational as much as possible during the construction
                phase.
              </p>
            </div>
            <div className="datamanagements-content-two">
              <h3>EQUIPMENT & SYSTEMS INCLUDED</h3>
              <ul>
                <li>
                  Amine contactor and parts of the amine regeneration system
                </li>
                <li>Shell and tube heat exchangers</li>
                <li>Water chiller package</li>
                <li>LACT unit</li>
                <li>Gas sales meter</li>
                <li>Air cooler</li>
              </ul>
            </div>
          </div>
          <div className="datamanagements-last-right">
            <h4>Scope</h4>
            <div className="datamanagement-services">
              <ul>
                <li>Project management and controls</li>
                <li>Process engineering and design</li>
                <li>Mechanical engineering and piping design</li>
                <li>Civil engineering and foundation and grading design</li>
                <li>Electrical and instrumentation engineering and design</li>
                <li>Construction management/onsite construction support</li>
                <li>Workpack development</li>
                <li>Engineering field support</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="datamanagements-middle">
          <div className="datamanagements-middle-content">
            <h3>Compressor Station Rehabilitation</h3>
            <span>RPotter County, PA, USA</span>
          </div>
          <div className="datamanagements-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/istockphoto-694058048-170667a.jpg"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagements-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to design and reconstruct a compressor
                station in Potter County, Pennsylvania. The compressor station
                required modifications to safely return to operations. M&H’s
                scope included PreFEED, FEED, detailed design, and onsite
                construction support.
              </p>
              <p>
                The primary focus was the replacement of the dehydration system
                and all above yard piping. The scope also included modernizing
                the facility by converting valve actuators from power gas to
                instrument air and replacement of the existing compressor
                headers. The HMI consoles were relocated from the control room
                to the auxiliary building.
              </p>
            </div>
            <div className="datamanagements-content-two">
              <h3>EQUIPMENT & SYSTEMS INCLUDED</h3>
              <ul>
                <li>Mol sieve towers</li>
                <li>Valve switching skids</li>
                <li>Regeneration gas coolers</li>
                <li>ICP instrumentation</li>
                <li>Control valves</li>
                <li>Actuated valves</li>
                <li>Instrument air package</li>
                <li>Pressure safety valves</li>
                <li>Facility lighting</li>
                <li>Operator console HMIs</li>
                <li>Fire & gas detection system</li>
                <li>MCC and switchgear</li>
                <li>Pipe and fittings</li>
                <li>Meter building panels</li>
                <li>ESD and PSD system</li>
                <li>BDV system</li>
              </ul>
            </div>
          </div>
          <div className="datamanagements-last-right">
            <h4>Scope</h4>
            <div className="datamanagement-services">
              <ul>
                <li>Project management and controls</li>
                <li>Process engineering and design</li>
                <li>Mechanical engineering and piping design</li>
                <li>Civil engineering and foundation design</li>
                <li>
                  Electrical/instrumentation engineering and control system
                  integration
                </li>
                <li>Procurement support services</li>
                <li>Perform blowdown, vent, and dispersion studies</li>
                <li>Work pack development</li>
                <li>Onsite construction support</li>
                <li>Onsite engineering support</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="datamanagement-middle">
          <div className="datamanagement-middle-content">
            <h3>Compressor Station & Treating Facility</h3>
            <span>Silt, CO, USA</span>
          </div>
          <div className="datamanagement-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023…2b0-a5cb-694291797a6b-120320-gd-Compressor03.webp"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagements-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to design and construct a compressor
                station and gas treating facility located in Silt, Colorado.
              </p>
              <p>
                The facility contains gas compression and treating equipment
                with a handling capacity of 40 MMSCFD. This project had unique
                challenges due to the accessibility of the location. Detailed
                construction planning had to be done due to the remote location
                and weather challenges. The facility was designed to allow for
                future expansion up to 150 MMSCFD.
              </p>
            </div>
            <div className="datamanagements-content-two">
              <h3>EQUIPMENT & SYSTEMS INCLUDED</h3>
              <ul>
                <li>2 & 3 phase separators</li>
                <li>Shell and tube heat exchangers</li>
                <li>
                  Ultrasonic metering for custody transfer with chromatograph
                  and H2O analyzer
                </li>
                <li>Thermal oxidizer</li>
                <li>Glycol dehydration unit</li>
                <li>Amine treating system</li>
                <li>API 12F storage tanks (produced and fresh water)</li>
                <li>Process control system (FactoryTalk operating platform)</li>
                <li>Fire and gas detection system</li>
                <li>Fuel gas conditioning</li>
                <li>CAT 3612/Ariel compressor packages</li>
                <li>Microturbine power generators</li>
                <li>PDC/MCC building</li>
                <li>
                  Buildings for winterization of compression, amine, and
                  metering equipment
                </li>
              </ul>
            </div>
          </div>
          <div className="datamanagements-last-right">
            <h4>Scope</h4>
            <div className="datamanagement-services">
              <ul>
                <li>Project management and controls</li>
                <li>Process engineering and design</li>
                <li>Mechanical engineering and piping design</li>
                <li>Civil engineering and foundation design</li>
                <li>
                  Electrical/instrumentation engineering and control system
                  integration
                </li>
                <li>Procurement</li>
                <li>Vendor coordination and expediting</li>
                <li>Fabrication inspection and QC (Quality Control)</li>
                <li>Operating procedures development</li>
                <li>Operations training</li>
                <li>Permitting support</li>
                <li>Construction support</li>
                <li>Commissioning and operations support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EngineeringProject;
