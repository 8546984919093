import React from "react";
import "../Engineering-Proj/EngineeringProject.css";
import "../DataMangement/DataManagement.css";
import "./RiskManagementProject.css";
import Footer from "../../Footer/Footer";
function RiskManagementProject() {
  return (
    <>
      <div>
        <div className="Datamanagement-first">
          <img
            src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/pexels-brett-sayles-4276427.jpg"
            alt="show"
          />
          <h2>Risk Management</h2>
        </div>
        <div className="datamanagement-middle">
          <div className="datamanagement-middle-content">
            <h3>PSM Program Development</h3>
            <span>West Texas, USA</span>
          </div>
          <div className="datamanagement-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/pexels-photo-257700-768x512.jpeg"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagement-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to develop a Process Safety Management
                (PSM) program for a liquid handling facility located in West
                Texas. Tecnicoe worked with the operator to determine if the
                facility falls under the oversight of PSM and then developed all
                required documentation and training in order to comply with 29
                CFR 1910.119.
              </p>
            </div>
          </div>
          <div className="datamanagement-last-right">
            <h4>Scope</h4>
            <p>
              Tecnicoe developed a complete PSM program that fully complies with
              all elements of 29 CFR 1910.119. Along with the overall program
              document, Tecnicoe developed individual documents and other
              required forms and programs to ensure the facility is in
              compliance.
            </p>
            <div className="datamanagement-services-2">
              <ul>
                <li>Employee participation</li>
                <li>Process safety information</li>
                <li>Process hazards analysis</li>
                <li>Operating procedures</li>
                <li>Training</li>
                <li>Contractors</li>
                <li>Pre-startup safety review</li>
                <li>Mechanical integrity</li>
                <li>Hot work permit</li>
                <li>Management of change</li>
                <li>Incident investigation</li>
                <li>Emergency planning and response</li>
                <li>Compliance audits</li>
                <li>Trade secrets</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="datamanagements-middle">
          <div className="datamanagements-middle-content">
            <h3>Acquisition Due Diligence Support</h3>
            <span>West Texas USA</span>
          </div>
          <div className="datamanagements-middle-img">
            <img
              src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/audits-768x461.png"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagements-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to provide due diligence to a midstream
                company during their acquisition of a facility located in West
                Texas. The facility consisted of a pipeline gathering system,
                nine compressor stations, and one process plant. A review of all
                available facility documentation including: design specs,
                pipeline MAOP calculations, ProMax process model, NACE
                documentation, and other process safety information. In
                addition, Tecnicoe reviewed the facility corrosion monitoring
                program, all regulatory compliance items, and the mechanical
                integrity program.
              </p>
            </div>
          </div>
          <div className="datamanagements-last-right">
            <h4>Scope</h4>
            <p>
              Tecnicoe provided a report summarizing findings and providing
              recommendations. This report was used by the purchasing company
              during the final evaluation of the facility.
            </p>
          </div>
        </div>
        <div className="datamanagements-middle">
          <div className="datamanagements-middle-content">
            <h3>PSM Program Development</h3>
            <span>Southeast New Mexico, USA</span>
          </div>
          <div className="datamanagements-middle-img">
            <img
              src="	https://www.tecnicoepc.com/wp-content/uploads/2023/02/about-768x576.jpg"
              alt="show"
            />
          </div>
        </div>
        <div className="datamanagement-last-div">
          <div className="datamanagements-last-left">
            <div className="datamanagement-last-content-p">
              <h3>DESCRIPTION</h3>
              <p>
                Tecnicoe was contracted to develop a PSM program based on the 14
                elements of 29 CFR 1910.119. In addition, the program was
                developed based on the following industry best practices and
                standards :
              </p>
            </div>
            <div className="datamanagement-content-two">
              <ul style={{ textAlign: "justify" }}>
                <li>
                  Code of Federal Regulations 49 CFR 192 – Transportation of
                  Natural and Other Gas by Pipeline, Subpart L – Operations and
                  Subpart N – Qualification of Pipeline Personnel;
                </li>
                <li>
                  Code of Federal Regulations 49 CFR 195 – Transportation of
                  Hazardous Liquids by Pipeline, Subpart A – General, Subpart B
                  – Annual, Accident, and safety-related condition reporting,
                  Subpart C – Design Requirements;
                </li>
                <li>
                  State regulatory and local municipal requirements where
                  operations will take place.
                </li>
              </ul>
            </div>
          </div>
          <div className="datamanagements-last-right">
            <h4>Scope</h4>
            <p>
              Tecnicoe developed a written process safety information plan,
              process hazards analysis plan, contractor plan, PSSR plan, hot
              work permit plan, management of change plan, incident
              investigation plan and procedure, emergency response plan, and
              audit plan that complied with the requirements of 29 CFR 1910.119.
            </p>
            <p>
              As part of the overall PSM program, Tecnicoe developed a
              maintenance integrity program, including the establishment of a
              CMMS system and maintenance procedures that covered all key
              equipment, including:
            </p>
            <div className="datamanagement-services">
              <ul>
                <li>Pressure vessels and storage tanks</li>
                <li>Piping systems</li>
                <li>Relief and vent systems and devices</li>
                <li>Emergency shutdown systems</li>
                <li>Controls</li>
                <li>Pumps</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RiskManagementProject;
