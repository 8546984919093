import React from "react";
import "../../../global.css";

function Footer() {
  return (
    <>
      <div className="footer">
        <img
          src="https://i.ibb.co/BLGf06g/Pi7-Tool-1706953497488.jpg"
          alt="Pi7-Tool-1706953497488"
          className="footer-img"
          border="0"
        />
        <div className="car"></div>
        <div className="cycle"></div>
      </div>
      <p className="footer_paragh">
        Copyright © tecnicoepc.com by All Right Reserved.{" "}
      </p>
    </>
  );
}

export default Footer;
