import React from "react";
import "../RiskManagement/RiskManagement.css";
import "./Substainability.css";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";

function SocialFactor() {
  return (
    <>
    <div className="risk-container">
      <div className="enterprise-first">
        <img
          src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/pexels-photomix-company-172505.jpg"
          alt="show"
        />
        <h2>Understanding ESG: Social Factors</h2>
      </div>
      <div className="engineer-middle">
        <div className="engineer-middle-content">
          <h5>ESG Scope & Impact</h5>
          <h4>Create Value Through Environmental Sustainability</h4>
        </div>
        <div className="engineer-middle-img">
          <img
            src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/1024x699xESG-Infograph-S-1024x699.png.pagespeed.ic_.ZHc8ktpH-S-768x524.png"
            alt="show"
          />
        </div>
      </div>
      <div className="engineer-paragh">
        <h3>Summary</h3>
        <p>
          The social component of ESG centers on relationships. In particular,
          it addresses how a company manages its relations with its employees,
          financial stakeholders, the communities in which it operates, and the
          broader political environment. How adept is the company in recognizing
          and adapting successfully to social trends, labor movements, or
          political developments? Social risks and opportunities have direct
          impacts on a company’s financial performance and public perception as
          a responsible corporate citizen.
        </p>
      </div>
      <div className="engineer-last-div">
        <div className="engineer-last-left">
          <img
            src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/file76w18ej6hmh1deeenbnb-1567533001-768x512.jpg"
            alt="show"
          />
        </div>
        <div className="engineer-last-right">
          <h5>Social Themes & Issues</h5>
          <div className="engineer-serviced">
            <p>For every action, there is an equal and opposite reaction.</p>
            <p>
              Newton’s third law of motion applies not only in the physical
              realm, but often at the intersection of business and social
              interest:
            </p>
            <p>
              For every business decision, there can be unintended social
              consequences.
            </p>
            <p>
              For example, the business decision to cut costs by adjusting
              sourcing for product materials. On paper, it sounds smart and
              profitable.
            </p>
            <p>
              But what are labor conditions like in the new location? Are
              employees or contractors treated fairly? Do they earn a living
              wage? Will your company’s sourcing encourage or increase unfair
              treatment?
            </p>
            <p>
              Maintaining the balance between social responsibility and
              profitability requires constant and vigilant self-examination.
            </p>
            <p>
              The key themes and issues included in most social sustainability
              assessments are summarized below.
            </p>
          </div>
        </div>
      </div>
      <div className="engineer-last-div">
        <div className="engineer-last-left">
          <div className="heading-one">
            <h3>Human Capital</h3>
            <ul>
              <li>Labor Management</li>
              <li>Human Capital Development</li>
              <li>Health and Safety</li>
              <li>Supply Chain Labor Standards</li>
            </ul>
          </div>
          <div className="heading-two">
            <h3>Product Liability</h3>
            <ul>
              <li>Product Safety and Quality</li>
              <li>Chemical Safety</li>
              <li>Financial Product Safety</li>
              <li>Privacy and Data Security</li>
              <li>Responsible Investment</li>
              <li>Health and Demographic Risk</li>
            </ul>
          </div>
          <div className="heading-three">
            <h3>Stakeholder Opposition</h3>
            <ul>
              <li>Controversial sourcing</li>
            </ul>
          </div>
          <div className="heading-four">
            <h3>Social Opportunities</h3>
            <ul>
              <li>Access to Communications</li>
              <li>Access to Finance</li>
              <li>Access to Healthcare</li>
              <li>Opportunities in Health and Nutrition</li>
            </ul>
          </div>
        </div>
        <div className="engineer-last-right">
          <div className="right-images">
            <img
              src="https://images.unsplash.com/photo-1560179376-2570140bed14?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="show"
            />
          </div>
        </div>
      </div>
      <div className="datamanagement-last-div">
        <div className="datamanagements-last-left">
          <div className="datamanagement-last-content-p">
            <h3>Impacts on Business</h3>
            <p>
              Ultimately, companies exist to make money. If they don’t, they
              cannot provide the jobs and social benefits necessary for human
              survival. However, in today’s world, social responsibility and
              profitability go hand in hand.
            </p>
            <h3>Raising Capital</h3>
            <p>
              Social factors increasingly influence the availability of capital.
              A company that is insensitive to (or is unaware of) the social
              impacts of their business decisions will not receive the ESG
              scores necessary to attract investors or progress toward an IPO.
            </p>
            <h3>Sales & Market Share</h3>
            <p>
              More and more consumers are considering social factors in their
              purchase decsions. Over time, the market will reward companies
              that minimize their exposure to social issues. Innovative
              solutions to social issues may also uncover revenue-generating
              opportunities in new markets, products, or service lines.
            </p>
            <h3>Human Resources</h3>
            <p>
              Addressing equity, diversity, safety, and quality of life issues
              can improve a company’s ability to attract and retain talented
              employees and boost productivity through increased internal morale
              and employee engagement.
            </p>
            <h3>Product Liability</h3>
            <p>
              Proper measures to protect public health and safety in delivering
              products and services avoids risks such as recalls, penalties,
              fines, and loss of brand value.
            </p>
            <p>
              Socially sustainability is a worthwhile investment, both in terms
              of commercial success and the wellbeing of the people and
              communities it impacts.
            </p>
          </div>
        </div>
        <div className="datamanagements-last-right">
          <img
            style={{ marginTop: 180 }}
            src="https://www.tecnicoepc.com/wp-content/uploads/2023/02/MH_ESG_Social3.png"
            alt="show"
          />
        </div>
      </div>
      <div className="engineer-middle">
        <div className="engineer-middle-content">
          <h4
            style={{
              color: "#02678b",
              fontFamily: "Poppins",
              letterSpacing: 1.2,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Learn More
          </h4>
          <h2>
            Visit our other “Understanding ESG” pages to learn more about
            sustainability issues and how they affect your business.
          </h2>
        </div>
        <div className="engineer-middle-img">
          <img
            src="	https://www.tecnicoepc.com/wp-content/uploads/2023/02/pexels-sameera-madusanka-1572036.jpg"
            alt="show"
          />
          <div className="button-div">
            <button type="sumbit" className="button">
              <Link to="/environmental-factors">Environmental Factor</Link>
            </button>
            <button type="sumbit" className="button">
              <Link to="/governance-factors">Governance Factor</Link>
            </button>
          </div>
        </div>
      </div>
      <div className="engineer-paragh">
        <h3>Full-Service Sustainability Consulting</h3>
        <p className="our-implement">
          Tecnicoe is uniquely qualified to help you manage ESG risks and
          opportunities, with more than 40 years of consulting experience in a
          diverse range of disciplines and applications.
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
}

export default SocialFactor;
